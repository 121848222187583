import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

// project-imports
import useAuth from "hooks/useAuth"

// types
import { GuardProps, UserRoles } from "types/auth"

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const allowedRoles = () => {
    if (
      user?.role_ids?.includes(UserRoles.SCOUTING_ACCESS) ||
      user?.role_ids?.includes(UserRoles.BLACK_LIST_VALIDATOR)
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`/`, {
        state: {
          from: location.pathname
        },
        replace: true
      })
    } else {
      if (!allowedRoles()) {
        navigate("/", {
          state: {
            from: ""
          },
          replace: true
        })
      }
    }
  }, [isLoggedIn, navigate, location])

  return children
}

export default AuthGuard
