import { createContext, ReactElement, useReducer } from "react"
import { BlacklistItem, Track, TracksContextType } from "../types/tracks"
import tracksReducer, { initialTracksState } from "../store/reducers/tracks"
import axios from "utils/axios"
import { FETCH_BLACKLIST, FETCH_TRACKS } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase, mapToSnakeCase, toSnakeCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import { getFilterText, toastMessage } from "../utils"

const TracksContext = createContext<TracksContextType | null>(null)

export const TracksProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(tracksReducer, initialTracksState)

  const fetchTracks = async (
    limit: number,
    offset: number,
    filter?: any,
    orderBy?: string,
    search?: string,
    albumId?: number,
    artistId?: number,
    playlistId?: string
  ) => {
    const response = await axios(
      `/tracks/?limit=${limit}&offset=${offset}${getFilterText(filter)}${orderBy ? `&order_by=${orderBy}` : ""}${
        search ? `&search=${search}` : ""
      }${albumId ? `&album_id=${albumId}` : ""}${artistId ? `&artist_id=${artistId}` : ""}${
        playlistId ? `&playlist_id=${playlistId}` : ""
      }`,
      { method: "get" }
    )

    dispatch({
      type: FETCH_TRACKS,
      payload: { ...state, paginatedTracks: mapPaginatedDataToCamelCase<PaginatedData<Track>>(response.data) }
    })
  }

  const fetchBlacklist = async (limit: number, offset: number, search?: string) => {
    const response = await axios(`/black-list/?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ""}`, {
      method: "get"
    })

    dispatch({
      type: FETCH_BLACKLIST,
      payload: {
        ...state,
        paginatedBlacklist: mapPaginatedDataToCamelCase<PaginatedData<BlacklistItem>>(response.data)
      }
    })
  }

  const updateTrack = async (trackToUpdate: Track, keyList: string[]) => {
    const keys = keyList.map((key) => toSnakeCase(key)).join(",")
    const response = await axios(`/tracks/update_status/?keys=${keys}`, {
      method: "put",
      data: mapToSnakeCase(trackToUpdate)
    })

    if (!response.data.success) {
      toastMessage("Error saving track", "error")
      return
    }

    const results = [...state.paginatedTracks.results].map((track: Track) => {
      if (track.id === trackToUpdate.id) {
        for (let i = 0; i < keyList.length; i++) {
          const _key = keyList[i]
          // @ts-ignore
          track[_key] = trackToUpdate[_key]
        }
      }
      return track
    })
    toastMessage("Successfully saved track", "success")

    dispatch({
      type: FETCH_TRACKS,
      payload: {
        ...state,
        paginatedTracks: { ...state.paginatedTracks, results }
      }
    })
  }

  return (
    <TracksContext.Provider value={{ ...state, fetchTracks, fetchBlacklist, updateTrack }}>
      {children}
    </TracksContext.Provider>
  )
}

export default TracksContext
