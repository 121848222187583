import { useRoutes } from "react-router-dom"

// project-imports
import ScoutingPublicRoutes from "./ScoutingPublicRoutes"
import ScoutingRoutes from "./ScoutingRoutes"

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    ScoutingPublicRoutes,
    ScoutingRoutes
  ])
}
