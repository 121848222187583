import { ReactElement } from "react"

// ==============================|| TYPES - AUTH  ||============================== //


export enum UserRoles {
  SCOUTING_ADMIN = 1,
  SCOUTING_ACCESS = 2,
  BLACK_LIST_VALIDATOR = 3,
  ARTIST_VALIDATOR = 4,
  OFFERS_ACCESS = 10,
  SIMENA_ACCESS = 20
}

export type GuardProps = {
  children: ReactElement | null
}

export type UserProfile = {
  id?: string
  email?: string
  first_name?: string
  last_name?: string
  avatar?: string
  image?: string
  role?: string
  tier?: string
  role_ids?: number[]
}

export interface AuthProps {
  isLoggedIn: boolean
  isInitialized?: boolean
  roleIds?: number[]
  user?: UserProfile | null
  token?: string | null
}

export interface AuthActionProps {
  type: string
  payload?: AuthProps
}

export interface InitialLoginContextProps {
  isLoggedIn: boolean
  isInitialized?: boolean
  user?: UserProfile | null | undefined
}

export interface JWTDataProps {
  userId: string
}

interface OffersAuthProps {
  user_existed: boolean
  success: boolean
}

export type JWTContextType = {
  isLoggedIn: boolean
  isInitialized?: boolean
  roleIds?: number[]
  user?: UserProfile | null | undefined
  logout: () => void
  login: (email: string, password: string) => Promise<void>
  loginSpotify: (code: string) => Promise<void>
  sendCodeValidation: (username: string, password: string) => Promise<boolean>
  validateEmailAddress: (email: string, code: string) => Promise<boolean>
  updateProfile: VoidFunction
}
