import { camelCase, isArray, isBoolean, isNumber, isString, mapKeys, snakeCase } from "lodash"

export const toSnakeCase = (value: string) => {
  return value.replace(/([A-Z])/g, "_$1").toLowerCase()
}

export const mapToCamelCase = <T extends { [key: string]: any }>(object: any): T => {
  const result: any = {}
  mapKeys(
    object,
    (value, key) =>
      (result[camelCase(key)] = isArray(value)
        ? mapListToCamelCase(value)
        : isBoolean(value) || isString(value) || isNumber(value) || !value
        ? value
        : mapToCamelCase(value))
  )
  return result
}

export const mapListToCamelCase = <T extends { [key: string]: any }>(objectList: any[]): T[] =>
  objectList.map((object) =>
    isBoolean(object) || isString(object) || isNumber(object) || !object ? object : mapToCamelCase<T>(object)
  )

export const mapPaginatedDataToCamelCase = <T extends { [key: string]: any }>(paginatedData: T): T => ({
  ...paginatedData,
  results: mapListToCamelCase(paginatedData.results)
})

export const mapToSnakeCase = (object: any) => {
  const result: any = {}
  mapKeys(
    object,
    (value, key) =>
      (result[snakeCase(key)] = isArray(value)
        ? mapListToSnakeCase(value)
        : isBoolean(value) || isString(value) || isNumber(value) || !value
        ? value
        : mapToSnakeCase(value))
  )
  return result
}

export const mapListToSnakeCase = <T>(objectList: any[]): any[] =>
  objectList.map((object) =>
    isBoolean(object) || isString(object) || isNumber(object) || !object ? object : mapToSnakeCase(object)
  )
