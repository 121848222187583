import { createContext, ReactElement, useReducer } from "react"
import axios from "utils/axios"
import { FETCH_CONFIRMED_OFFERS } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import { ReportsContextType, ConfirmedOffer, ConfirmedOfferTypes } from "types/reports"
import reportsReducer, { initialReportsState } from "store/reducers/reports"

const ReportsContext = createContext<ReportsContextType | null>(null)

export const ReportsProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(reportsReducer, initialReportsState)

  const fetchConfirmedOfferList = async (
    orderBy: string,
    limit: number,
    offset: number,
    isPublic: boolean,
    artist?: string,
    username?: string,
    id?: number
  ) => {
    const searchParam = artist ? `&artist=${artist}` : ""
    const usernameParam = username ? `&username=${username}` : ""
    const idParam = id ? `&id=${id}` : ""

    const params = `?limit=${limit}&offset=${offset}&order_by=${orderBy}&scouting=1${searchParam}${usernameParam}${idParam}`

    const baseUrl = isPublic ? "/public/offers/confirmed_offers" : `/reports/confirmed-offers`
    const url = `${baseUrl}/${params}`
    const response = await axios(url, { method: "get" })

    dispatch({
      type: FETCH_CONFIRMED_OFFERS,
      payload: {
        ...state,
        paginatedConfirmedOfferList: mapPaginatedDataToCamelCase<PaginatedData<ConfirmedOffer>>(response.data)
      }
    })
  }

  const updateConfirmedOffer = async (id: number, approved: boolean, type: string, offerUsd?: number) => {
    const response = await axios(`/reports/confirmed-offers/save/`, {
      method: "put",
      data: { id, offer_usd: offerUsd, approved, type }
    })

    if (!response.data.success) {
      return false
    }

    const results = [...state.paginatedConfirmedOfferList.results].map((confirmedOffer: ConfirmedOffer) => {
      if (confirmedOffer.id === id) {
        if (offerUsd && confirmedOffer.type === ConfirmedOfferTypes.ADVANCE) {
          confirmedOffer.advanceOfferUsd = offerUsd
        } else if (offerUsd && confirmedOffer.type === ConfirmedOfferTypes.BUYOUT) {
          confirmedOffer.buyoutOfferUsd = offerUsd
        }

        confirmedOffer.approved = approved
        confirmedOffer.type = type
      }
      return confirmedOffer
    })

    dispatch({
      type: FETCH_CONFIRMED_OFFERS,
      payload: {
        ...state,
        paginatedConfirmedOfferList: { ...state.paginatedConfirmedOfferList, results }
      }
    })

    return true
  }

  const updateSongSelectionOffer = async (confirmedOfferId: number, songSelectionId: number, offer: number) => {
    const response = await axios(`/reports/confirmed-offers/song_selection_offer/`, {
      method: "put",
      data: { confirmed_offer_id: confirmedOfferId, song_selection_id: songSelectionId, offer_usd: offer }
    })

    if (!response.data.success) {
      return false
    }

    return true
  }

  return (
    <ReportsContext.Provider
      value={{ ...state, fetchConfirmedOfferList, updateConfirmedOffer, updateSongSelectionOffer }}
    >
      {children}
    </ReportsContext.Provider>
  )
}

export default ReportsContext
